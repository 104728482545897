import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { type EnhancedSubject } from 'types/shared.ts';

export const handleAPIError = (error: any, displayError = false) => {
  if (process.env.NODE_ENV !== "production") {
   console.error(error);
  }

  if (error && Object.getOwnPropertyNames(error).includes("response"))   {
    const message = error?.response?.data?.message;

    if (displayError) {
      console.log("message", message)
    }

    return message
  }
};

export const handleResponseMessage = (data: any, displayMessage = false) => {
  if (data && Object.getOwnPropertyNames(data).includes("data"))   {
    const message = data?.data?.message;

    if (displayMessage) {
      console.log("message", message)
    }

    return message
  }
};

export const waitFor = (duration: number) => {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, duration)
  })
}
 
export const getPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(posData => {
      resolve(posData);
    }, error => {
      reject(error);
    })
  })
}

export const validateEmail = {
  value: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
  message: 'Please enter a valid email address'
}

export const validatePassword = {
  value: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&\\-]{10,}$"),
  message: 'Password must be at least 10 characters long and include uppercase and lowercase letters, and numbers'
}

export const validatePhoneNumber = {
  value: new RegExp(/^(\+?\d{1,2}\s?)?(\(?\d{3}\)?)[\s\-]?\d{3}[\s\-]?\d{4}$/),
  message: 'Please enter a valid phone number'
};

export const lArray = (object: object) => Object.values(Object.assign(object));

export const nameAcronim = (name: string) => {
  const matches = name.match(/\b(\w)/g);
  if (!matches) return;

  return matches.join('');
}

export const formatDate = (date: Date | string, format: string) => {
  format = format || 'MMMM Do YYYY, h:mm:ss a';
  if (date) return moment(date).format(format);
  return moment().format(format);
}

export function haversineDistance(coord1: {lat: number; lon: number}, coord2: {lat: number; lon: number}) {
  // Radius of the Earth in kilometers
  const R = 6371;
  // Conversion factor from kilometers to miles
  const KM_TO_MILES = 0.621371;

  // Convert degrees to radians
  const lat1 = Number(coord1.lat) * Math.PI / 180;
  const lon1 = Number(coord1.lon) * Math.PI / 180;
  const lat2 = Number(coord2.lat) * Math.PI / 180;
  const lon2 = Number(coord2.lon) * Math.PI / 180;

  // Haversine formula
  const dlat = lat2 - lat1;
  const dlon = lon2 - lon1;

  const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dlon / 2) * Math.sin(dlon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distanceKm = R * c;

  // Convert distance to miles
  const distanceMiles = distanceKm * KM_TO_MILES;

  return distanceMiles.toFixed(2);
}

export const getTimeDifferenceInMinutes = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();
  
  // Calculate the difference in milliseconds
  const differenceInMs = Math.abs(time2 - time1);
  
  // Convert milliseconds to days, hours, and minutes
  const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
  
  // Construct the result string
  let result = "";
  if (days > 0) result += `${days} d `;
  if (hours > 0 || days > 0) result += `${hours} h `;
  result += `${minutes} m`;
  
  return result;
}

export const getTimeDifferenceFromNow = (date: string | Date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  if (isNaN(inputDate.getTime())) {
      throw new Error("Invalid date provided");
  }

  let differenceInMilliseconds = currentDate.getTime() - inputDate.getTime();

  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  differenceInMilliseconds %= (1000 * 60 * 60 * 24);

  const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  differenceInMilliseconds %= (1000 * 60 * 60);

  const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

  const result = {
      days: differenceInDays,
      hours: differenceInHours,
      minutes: differenceInMinutes
  };

  const formattedString = `${differenceInDays ? (differenceInDays + ' d '): ''} ${differenceInHours ? (differenceInHours + 'h ') : ''} ${differenceInMinutes} min`;

  return { result, formattedString };
}
export const hasExperiment = (experiments: { name: string; isAvailable: boolean; }[], experiment: string) => {
  const foundExperiment = experiments.find(x => x.name === experiment);
  if (foundExperiment) return foundExperiment.isAvailable;
  return false;
}

export const hasProfilePicture = (account: EnhancedSubject) => {
  return Boolean(account.imageUrl && account.imageUrl.hasOwnProperty('key') && account.imageUrl.key);
}

export const addDaysToDate = (date: string, days: number): Date => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    throw new Error("Invalid date string provided");
  }

  parsedDate.setDate(parsedDate.getDate() + days);
  return parsedDate;
};

export const formatLink = (url: string) => {
  // Trim leading/trailing spaces
  url = url.trim();

  // If the URL doesn't start with http:// or https://, add https://
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `https://${url}`;
  }

  // If the URL contains a domain but doesn't have 'www.' and isn't localhost, ensure it uses 'www.'
  const hasProtocol = url.startsWith('http://') || url.startsWith('https://');
  if (hasProtocol && !/^https?:\/\/localhost/.test(url)) {
      const protocol = url.split('://')[0];
      const restOfUrl = url.split('://')[1];
      if (restOfUrl && !restOfUrl.split('/')[0].includes('.')) {
          // Don't add www if it's an IP or localhost
          return url;
      }
      // Optional step: You could add further domain checks here if needed
      url = `${protocol}://${restOfUrl}`;
  }

  // Remove trailing slash if it's the only trailing character
  url = url.endsWith('/') && !url.match(/\/$/) ? url.slice(0, -1) : url;

  // Return the formatted URL
  return url;
}

export const calculateCommissionPercentage = (ratio: number) => {
  if (ratio <= 0 || ratio > 1) {
      throw new Error("Invalid ratio. It must be between 0 and 1.");
  }
  const commission = (1 - ratio) * 100;
  return `${commission.toFixed(0)}%`;
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Copied!');
  } catch (error) {
    console.error('Failed to copy text to clipboard:', error);
    toast.error('Failed to copy. Please try again.');
  }
};

export const getMonthsBetween = (checkIn: string, checkOut: string) => {
  const start = new Date(checkIn);
  const end = new Date(checkOut);

  const yearsDiff = end.getFullYear() - start.getFullYear();
  const monthsDiff = end.getMonth() - start.getMonth();

  return yearsDiff * 12 + monthsDiff;
}

export * from './address.ts';